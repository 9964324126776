
.App {
  text-align: center;
  min-height: 100vh;
  background: #404040;
  display:flex;
  flex-direction: column;
  
}
@media (min-width: 0px) {

  .app{
    flex-direction: row;
  }

}

@media (min-width: 600px) and (orientation:landscape) {

  .App{
    flex-direction: row;
  }
  header{
    flex:1
  }

}

h1{
  margin:0;
}

input[type="file"] {
  display: none;
}
.fancyButton {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 2.5rem;
  margin: 10px;
  background:#ffa800;
  border:0;
}